.error-boundary-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px;
  gap: 20px;
}
.error-boundary {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.error-boundary-image {
  width: 100%;
  max-width: 300px;
  height: auto;
}
.error-boundary-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
}
@media screen and (max-width: 768px) {
  .error-boundary-content {
    width: 100%;
  }
  .error-boundary-container {
    flex-direction: column;
  }
}
