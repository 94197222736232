.homePage__slider {
  width: 100%;
  height: 655px;
  position: relative;
  overflow: hidden;
  background: #000;
}
.homePage__slider .swiper {
  width: 100%;
  height: 100%;
  background: #000;
  cursor: pointer;
}

.homePage__slider .swiper-slide {
  font-size: 18px;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 60px;
}

.homePage__slider .parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

.homePage__slider .swiper-slide .title {
  font-size: 50px;
  line-height: 1.5;
  font-weight: 600;
  margin-top: 10%;
  margin-left: 15%;
  margin-bottom: 10px;
}

.homePage__slider .swiper-slide .subtitle {
  font-size: 21px;
}

.homePage__slider .swiper-slide .text {
  font-size: 14px;
  font-weight: 400;
  max-width: 400px;
  line-height: 1.3;
  margin-left: 15%;
}

.featured-desc-text {
  height: 80px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media screen and (max-width: 1024px) {
  .homePage__slider {
    height: 500px;
  }
}

@media screen and (max-width: 768px) {
  .homePage__slider {
    height: 400px;
  }
  .homePage__slider .swiper-slide .title {
    font-size: 30px;
  }
  .homePage__slider .swiper-slide .subtitle {
    font-size: 16px;
  }
  .homePage__slider .swiper-slide .text {
    font-size: 12px;
  }
}
