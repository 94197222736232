/* body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: inherit;
} */

/***
Custom Scrollbars
***/
::-webkit-scrollbar {
  width: 9px;
}

::-webkit-scrollbar-track {
  background-color: #eaeaea;
  border-left: 1px solid #cecece;
}

::-webkit-scrollbar-thumb {
  background-color: #cecece;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

::-webkit-scrollbar-track {
  border-radius: 0;
  box-shadow: none;
  border: 0;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  box-shadow: none;
  border: 0;
  border: 1px #fff solid;
}

.container ::-webkit-scrollbar {
  width: 5px;
}

.container ::-webkit-scrollbar-thumb {
  border: 0;
}
/*****/
/* Container */
/* .Container {
  max-width: 1200px;
  margin: 0 auto;
} */

/* Tablet - PC low resolution */
/* @media (min-width: 740px) and (max-width: 1023px) {
  .Container {
    width: 644px;
  }
} */
/* > PC low resolution */
/* @media (min-width: 1024px) and (max-width: 1239px) {
  .wide {
    width: 984px;
  }
} */
/* custom width output video braft editor */
.video-wrap video {
  max-width: 100%;
  height: auto;
}
/* custom blockquote braft editor */
blockquote {
  position: relative;
  background-color: #f1f2f3;
  border-left: 5px solid #ccc;
  color: #666;
  font-style: italic;
  margin: 0 0 10px;
  padding: 15px 20px;
}

@media (min-width: 768px) {
  blockquote {
    margin: 40px 60px;
  }
}

blockquote::before {
  content: "";
  pointer-events: none;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: var(--border-rad);
  box-shadow: inset -2px -2px 1px hsl(0 0% 100%), inset 2px 2px 4px hsl(0 0% 0% / 20%);
}

blockquote::after {
  content: "❝";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: -2px;
  transform: translate(-50%, -50%);
  width: 1.3em;
  height: 1.3em;
  background: white;
  box-shadow: 0 4px 5px -1px hsla(0 0% 0% / 20%);
  border-radius: 999px;
  display: grid;
  place-content: center;
  padding-top: 0.5em;
  color: var(--accent-color);
  font-size: 36px;
  font-style: normal;
  text-indent: 0;
}

pre code {
  background-color: #f1f2f3;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  font-family: "Roboto Mono", monospace;
  color: #666;
}
